@import "../../../assets/styles/variables.scss";

.manage-hosts {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .hosts-cards-list-section {
    padding: 8px;
  }
  .add-host-btn {
    width: 48px;
    height: 48px;
    position: fixed;
    right: 16px;
    bottom: 20px;
    z-index: 10;
  }
}
