@import "../../assets/styles/variables.scss";

.bottom-nav-bar {
  height: 64px;
  width: 100%;
  background: $white;
  box-shadow: 8px 0px 24px 0px rgba(0, 0, 0, 0.1);
  position: fixed;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}
