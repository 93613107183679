$primary: #6c70c5;
$disabledText: #6c70c54d;
$white: #ffffff;
$primaryText: #605c5c;
$secondaryText: #747474;
$primaryTextBlurred: rgba(96, 92, 92, 0.5);
$primaryBorder: #605c5c;
$primaryBorderBlurred: rgba(96, 92, 92, 0.15);
$error: #fd0118;
$security-tag-color: $primary;
$host-tag-color: #ff7f50;
$admin-tag-color: #40e0d0;
$visitor-tag-color: #00a36c;

:export {
  security-tag-color: $security-tag-color;
  host-tag-color: $host-tag-color;
  admin-tag-color: $admin-tag-color;
  visitor-tag-color: $visitor-tag-color;
}
