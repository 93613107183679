@import "../../assets/styles/variables.scss";

.security-guard-card {
  background-color: #ffffff;
  padding: 16px 0px;
  border-bottom: 1px solid $primaryBorderBlurred;
  .security-image-details-container {
    display: flex;
    align-items: center;
    .security-details-container {
      margin-left: 16px;
      .security-phone-number {
        display: block;
        margin-top: 4px;
        color: $secondaryText;
      }
    }
  }
  .see-more-btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 8px;
    .see-more-btn {
      color: $primary;
      cursor: pointer;
    }
  }
}
