@import "../../assets/styles/variables.scss";

.admin {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .screen-header {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    border-bottom: 1px solid $primaryBorder;
  }
  .admin-menu-section {
    padding: 8px 0px;
    .admin-menu {
      padding: 14px 10px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $primaryBorderBlurred;
      cursor: pointer;
      color: #0f5132;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
