@import "../../../assets//styles/variables.scss";

.customer-form {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .customer-form-input-section {
    padding: 10px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input-field {
      padding-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-prefix {
        .ms-TextField-fieldGroup {
          .ms-TextField-prefix {
            padding-left: 0px;
            padding-right: 12px;
            background-color: #ffffff;
          }
        }
      }
    }

    .email-input-field,
    .location-input-field {
      align-items: flex-start;
      .location-text,
      .email-text {
        height: 45px;
        display: flex;
        align-items: center;
      }
      .email-input-conatiner {
        .email-input {
          padding-top: 12px;
          &:first-child {
            padding-top: 0px;
          }
        }
        .add-email-btn-wrapper {
          margin-top: 12px;
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .add-email-btn {
            color: $primary;
            cursor: pointer;
          }
        }
      }
    }
  }
  .address-input-section {
    padding: 2px 10px 26px;
    border-bottom: 1px solid $primaryBorder;
    .address-input-container {
      height: 120px;
      margin-top: 12px;
      border-radius: 8px;
      background-color: rgba(250, 250, 250, 0.5);
      .address-textarea-container {
        height: 100%;
        .ms-TextField-wrapper {
          height: 100%;
          .ms-TextField-fieldGroup::after {
            border-radius: 8px;
          }
        }
      }
    }
  }
  .action-button-section {
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
