@import "../../../assets/styles/variables.scss";

.host-details {
  width: 100%;
  height: 100%;
  padding: 20px 16px;
  .host-details-section {
    padding: 8px;
    border-bottom: 1px solid $primaryBorder;
    .host-details-field-container {
      padding: 14px 0px;
      border-bottom: 1px solid $primaryBorderBlurred;
      .field-value {
        margin: 6px 0px 0px;
        display: block;
        color: $secondaryText;
        word-break: break-word;
      }
      &:last-child {
        border: none;
      }
    }
  }

  .action-button-section {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
