@import "../../assets/styles/variables.scss";

.login {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: url("../../assets/images/login-top-circles.svg") left top
      no-repeat,
    url("../../assets/images/login-bottom-circles.svg") right bottom no-repeat;
  background-size: auto;
  .login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: space-between;
    width: 100%;
    height: 100%;
    padding: 36px;
    .app-name-header {
      .app-name {
        font-size: 32px;
        font-weight: bold;
        text-align: center;
        line-height: 39px;
        letter-spacing: 1px;
      }
    }
    .login-inner-container {
      .app-subtitle {
        margin-top: 18px;
        font-size: 16px;
        font-weight: 500;
        line-height: 17px;
        letter-spacing: 0.5px;
        text-align: center;
        span {
          font-weight: bold;
        }
      }
      .login-image-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 10px auto;
      }
      .user-role-selection-container {
        margin: 40px auto 0px;
        .user-role-choicegroup {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          justify-content: center;
          width: 100%;
          .ms-ChoiceFieldGroup-flexContainer {
            display: flex;
            align-items: flex-start;
            justify-content: center;
            flex-wrap: wrap;
          }
          .ms-ChoiceField {
            width: 50% !important;
            &:nth-child(even) {
              width: 100px !important;
            }
          }
        }
      }
      .sign-up-text {
        margin: 20px auto;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .user-role-error-message-container {
        padding: 10px 0px 30px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .login-input-container {
        display: flex;
        flex-direction: column;
        align-items: center;

        .phone-input {
          margin-bottom: 18px;
        }
        .error-message {
          color: $error;
          text-align: center;
        }

        .phone-input ::placeholder {
          font-size: 16px;
          color: $primaryTextBlurred;
          text-align: center;
        }
        .otp-input-style {
          width: 40px !important;
          height: 40px;
          border: none;
          border: 0.5px solid $primaryTextBlurred;
          border-radius: 8px;
          font-size: 14px;
          font-weight: 400;
          color: $primaryText;

          &:focus-visible {
            border: none;
            outline: 1px solid $primary;
          }
        }
        .otp-input-error-style {
          border: 1px solid $error;
        }
        .message-container {
          margin: 12px 0px;
          .error-message {
            color: $error;
          }
        }
        .spinner-container {
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .login-actions-container {
          margin-top: 18px;
        }
      }
    }
  }
}
