body {
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  /* position: fixed; */
  overflow-y: scroll;
  font-family: "Monsterrat";
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

* {
  box-sizing: border-box;
}
