@import "./assets//styles/variables.scss";

#main {
  width: 100vw;
  height: 100vh;
  margin: 0;
  padding: 0;
  overflow-x: hidden;
  overflow-y: auto;
}

h1,
p {
  padding: 0;
  margin: 0;
}

.input-error {
  .ms-TextField-fieldGroup {
    border: 0.5px solid $error;
  }
  .ms-Dropdown {
    &:focus::after {
      border: 2px solid $error;
    }
    .ms-Dropdown-title {
      border: 0.5px solid $error;
    }
  }

  .ms-TextField-fieldGroup::after {
    border: 2px solid $error;
  }
}

.loader-wrapper {
  height: 100vh;
  width: 100vw;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  z-index: 99999999;
}
