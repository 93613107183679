.not-found {
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  background: url("../../assets//images/login-top-circles.svg") left top
      no-repeat,
    url("../../assets/images/login-bottom-circles.svg") right bottom no-repeat;
  .image-container {
    display: flex;
    align-items: center;
    flex-direction: column;
  }
}
