.manage-security-guards {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .security-guard-cards-list-section {
    padding: 8px;
  }
  .add-security-guard-btn {
    width: 48px;
    height: 48px;
    position: fixed;
    right: 16px;
    bottom: 20px;
    z-index: 10;
  }
}
