.confirmation-dialog {
  .ms-Dialog-main {
    width: 320px;
    min-width: 300px;
    min-height: 150px;
  }
  .ms-Dialog-title {
    text-align: center;
    padding: 20px;
  }
  .ms-Dialog-actionsRight {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .ms-Dialog-inner {
    padding: 10px 20px 20px;
  }
}
