@import "../../assets/styles/variables.scss";

.sliding-btn {
  height: 46px;
  max-width: 200px;
  background-color: #ffffff;
  border-radius: 4px;
  color: #ffffff;
  border: 2px solid $primary;
  box-shadow: 0px 8px 24px rgba(108, 112, 197, 0.15);
  &__caret {
    background: $primary !important;
  }
  &__overlay {
    background: $primary !important;
  }
}
