@import "../../assets//styles/variables.scss";

.home {
  padding: 0px 16px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  // background-color: red;
  .search-bar-section {
    width: 100%;
    height: 52px;
    position: fixed;
    left: 0;
    background-color: #ffffff;
    padding: 8px 16px;
    border-bottom: 0.5px solid $primaryBorderBlurred;
    z-index: 10;
    // box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.1);
    .search-bar-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & ::placeholder {
        font-size: 16px;
        letter-spacing: 0.3px;
        color: $primaryTextBlurred;
      }
      & :focus-visible {
        outline: none;
      }
      .icons-container {
        display: flex;
        align-items: center;
      }
    }
  }
  .visitors-section {
    padding-top: 84px;
    .screen-header {
      display: flex;
      justify-content: center;
      padding-bottom: 18px;
      border-bottom: 1px solid $primaryBorder;
    }
    .day-wise-visitor-container {
      border-top: 1px solid $primaryBorder;
      padding: 18px 8px 0px;

      .secondary-text {
        color: $secondaryText;
      }
      .date {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }
      .visitor-card:last-child {
        border: none;
      }
    }
    .day-wise-visitor-container :first-child {
      border: 0px;
    }
  }
  .pagination-section {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 0px 24px;
  }
}
.filter-callout-container {
  padding: 16px;
  .filter-menu-item {
    padding-bottom: 16px;
    .ms-Checkbox .ms-Checkbox-text {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
    }
    .ms-Checkbox.is-checked .ms-Checkbox-text {
      color: $primary;
    }
    // .ms-Checkbox-checkbox:hover {
    //   background-color: #6c70c517;
    // }
  }
}
[data-rsbs-overlay],
[data-rsbs-backdrop],
[data-rsbs-root]:after {
  z-index: 99999 !important;
}
