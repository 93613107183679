@import "../../assets/styles/variables.scss";

.customer-card {
  background-color: #ffffff;
  padding: 16px 0px;
  border-bottom: 1px solid $primaryBorderBlurred;
  .company-address {
    display: block;
    margin: 8px 0px;
    color: $secondaryText;
  }
  .see-more-btn-container {
    display: flex;
    justify-content: flex-end;
    .see-more-btn {
      color: $primary;
      cursor: pointer;
    }
  }
}
