@import "../../assets//styles//variables.scss";

.settings-page {
  width: 100%;
  height: 100%;
  padding: 32px 16px 0px;
  .screen-header {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    border-bottom: 1px solid $primaryBorder;
  }
  .settings-menu-section {
    padding: 8px 0px;
    .settings-menu {
      padding: 16px 30px;
      display: flex;
      align-items: center;
      border-bottom: 1px solid $primaryBorderBlurred;
      cursor: pointer;
      color: #0f5132;
      &:hover {
        background-color: #f5f5f5;
      }
    }
  }
}
