@import "../../../assets/styles/variables.scss";

.manage-customers {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .customer-cards-list {
    padding: 8px;
    // .customer-card:last-child {
    //   border-bottom: none;
    // }
  }
  .add-customer-btn {
    width: 48px;
    height: 48px;
    position: fixed;
    right: 16px;
    bottom: 20px;
    z-index: 10;
  }
}
