@import "../../assets/styles/variables.scss";

.wrong-user-card {
  padding: 64px 28px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .image-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text {
      margin-top: 24px;
      font-size: 22px;
      color: #f31313;
    }
  }
  .text-container {
    margin-top: 14px;
    text-align: center;
  }
}
