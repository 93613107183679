@import "../../assets/styles/variables.scss";
.invite-card {
  background-color: #ffffff;
  padding: 16px 0px;
  border-bottom: 1px solid $primaryBorderBlurred;
  .invite-image-details-container {
    display: flex;
    align-items: center;
    .invite-details-container {
      margin-left: 16px;
      .phone-number {
        display: block;
        margin: 8px 0px;
        color: $secondaryText;
      }
      .email {
        display: block;
        color: $secondaryText;
      }
    }
  }
  .delete-invite-btn-container {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    color: $error;
    margin-top: 8px;
    .delete-invite {
      margin-left: 8px;
      color: $error;
      cursor: pointer;
    }
  }
}
