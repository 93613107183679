@import "../../assets/styles/variables.scss";

.communication {
  width: 100%;
  height: 100%;
  padding: 20px 16px;
  .screen-header {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
    border-bottom: 1px solid $primaryBorder;
  }
  .communication-section {
    padding: 16px 8px 28px;
    border-bottom: 1px solid $primaryBorder;

    .date-badge {
      width: auto;
      padding: 4px 10px;
      border-radius: 4px;
      color: #ffffff;
      background-color: $primary;
    }

    .message {
      padding-bottom: 26px;
      .messager-details {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-bottom: 10px;

        .id-badge {
          margin-left: 16px;
          padding: 2px 4px;
          height: 16px;
          background-color: $primary;
          border-radius: 4px;
          display: flex;
          align-items: center;
        }
      }
      .message-box {
        width: auto;
        max-width: 300px;
        min-height: 60px;
        padding: 14px 18px;
        background-color: rgba(83, 89, 227, 0.05);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &.visitor {
        align-self: flex-start;
        justify-content: flex-start;
        .messager-details {
          justify-content: flex-start;
        }
        .message-box {
          background-color: #d1e7dd;
        }
      }
    }
    .start-end-time-container {
      padding: 10px 0px 0px;
    }
  }
  .action-button-container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 40px 0px;
  }
}
