@import "../../assets/styles/variables.scss";

.appointment {
  width: 100%;
  height: 100%;
  padding: 32px 16px 0px;
  .screen-header {
    display: flex;
    justify-content: center;
    padding-bottom: 18px;
  }
  .text-input-section {
    padding: 10px;
    border-top: 1px solid $primaryBorder;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input-field {
      padding-top: 12px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .disabled-input {
        .ms-TextField-fieldGroup {
          // padding: 0px 16px
          border: 0.5px solid #605c5c80;
          .ms-TextField-prefix {
            padding-left: 0px;
            padding-right: 12px;
            background-color: #ffffff;
          }
          .ms-TextField-field {
            color: $disabledText;
            font-weight: 700;
            background-color: #ffffff;
          }
        }
      }
    }
    .react-select-container.loadingHosts {
      .react-select__dropdown-indicator {
        display: none;
      }
    }
    .react-select-container {
      .react-select__dropdown-indicator,
      .react-select__clear-indicator {
        display: flex;
        padding: 8px;
        color: #a09c9c;
      }
      .react-select__control--is-disabled {
        background-color: #ffffff;
        .react-select__placeholder {
          color: $disabledText;
          font-weight: 700;
        }
        .react-select__indicators {
          display: none;
        }
      }
      .react-select__menu {
        z-index: 2;
      }
    }
  }
  .purpose-section {
    padding: 16px 10px;
    .purpose-input-container {
      margin-top: 12px;
      border-radius: 8px;
      background-color: rgba(250, 250, 250, 0.5);
      .audio-record-mode-container {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        .mic-image-container {
          width: 100px;
          height: 100px;
          background-color: #ffffff !important;
          border-radius: 50%;
          box-shadow: 0px 8px 24px rgba(96, 92, 92, 0.15);
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          position: absolute;
          z-index: 1;
        }
        .recording-animation {
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          border-radius: 50%;
          z-index: 0;
        }
        .recording-animation::after,
        .recording-animation::before {
          content: "";
          width: 98px;
          height: 98px;
          background-color: #fd0118;
          position: absolute;
          margin: auto;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          z-index: -1;
          border-radius: 50%;
          opacity: 0.8;
        }
        .recording-animation::before {
          animation: pulse 2s ease-out infinite;
        }
        .recording-animation::after {
          animation: pulse 2s 1s ease-out infinite;
        }

        @keyframes pulse {
          100% {
            transform: scale(2);
            opacity: 0;
          }
        }
      }
      .audio-recorded-mode-container {
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        .images-container {
          width: 100px;
          .top-images-container {
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .bottom-images-container {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin-top: 40px;
          }
        }
      }
      .purpose-textarea-container {
        height: 100%;
        .ms-TextField-wrapper {
          height: 100%;
          .ms-TextField-fieldGroup::after {
            border-radius: 8px;
          }
        }
      }
    }
  }
  .purpose-type-selection-section {
    border-top: 1px solid $primaryBorder;
    padding: 14px 8px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .audio-play-conatiner {
      display: flex;
      align-items: center;
      .red-dot {
        width: 12px;
        height: 12px;
        background-color: #fd0118;
        border-radius: 50%;
      }
      .audio-file-time {
        margin: 0px 8px;
      }
    }

    .purpose-type-selection-conatiner {
      border-left: 2px solid $primaryBorder;
      padding-left: 16px;
      justify-self: flex-end;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .action-button {
    padding: 12px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.request-sent-modal-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  .request-sent-text {
    font-size: 28px;
    font-weight: 700;
    color: $primary;
    padding: 0px 0px 32px;
  }
}
