@import "../../../assets//styles/variables.scss";

.host-form {
  padding: 32px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .host-form-input-section {
    padding: 10px 10px 20px;
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
    .input-field {
      padding-top: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      .input-prefix {
        .ms-TextField-fieldGroup {
          .ms-TextField-prefix {
            padding-left: 0px;
            padding-right: 12px;
            background-color: #ffffff;
          }
        }
      }
      .disabled-input {
        .ms-TextField-fieldGroup {
          border: 0.5px solid #605c5c80;
          .ms-TextField-field {
            color: $disabledText;
            font-weight: 700;
            background-color: #ffffff;
          }
        }
      }
    }
  }
  .action-button-section {
    border-top: 1px solid $primaryBorder;
    padding-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
