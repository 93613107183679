@import "../../assets//styles/variables.scss";
.top-nav-bar {
  width: 100%;
  height: 72px;
  display: flex;
  padding: 0px 0px 0px 24px;
  align-items: center;
  background-color: #ffffff;
  justify-content: space-between;
  &.nav_sticky {
    position: fixed;
    z-index: 9999;
    border-bottom: 0.5px solid $primaryBorderBlurred;
  }
  &.nav_fixed {
    position: fixed;
    z-index: 9999;
  }

  .logo {
    font-size: 20px;
    font-weight: 700;
    line-height: 27px;
  }
  .profile-container {
    height: 100%;
    display: flex;
    align-items: center;
    background-color: #ffffff;
    .vertical-bar {
      margin-left: 16px;
      width: 10px;
      height: 100%;
      border-top-left-radius: 20px;
      border-bottom-left-radius: 20px;
    }
  }
}
.callout-container {
  .callout-menu {
    text-align: center;
    cursor: pointer;
    &.account {
      padding: 18px 8px 10px;
      border-bottom: 1px solid $primaryBorderBlurred;
    }
    &.logout {
      padding: 10px 8px 18px;
    }
  }
}
