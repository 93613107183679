@import "../../assets//styles/variables.scss";

.host-page {
  padding: 0px 16px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .search-bar-section {
    width: 100%;
    height: 52px;
    position: fixed;
    z-index: 10;
    left: 0;
    background-color: #ffffff;
    padding: 8px 16px;
    border-bottom: 0.5px solid $primaryBorderBlurred;
    // box-shadow: 0px 16px 20px rgba(0, 0, 0, 0.1);
    .search-bar-container {
      display: flex;
      align-items: center;
      justify-content: space-between;

      & ::placeholder {
        font-size: 16px;
        letter-spacing: 0.3px;
        color: $primaryTextBlurred;
      }
      & :focus-visible {
        outline: none;
      }
      .icons-container {
        display: flex;
        align-items: center;
      }
    }
  }
  .visitors-section {
    padding-top: 84px;
    .screen-header {
      display: flex;
      justify-content: center;
      padding-bottom: 18px;
      border-bottom: 1px solid $primaryBorder;
    }
    .day-wise-visitor-container {
      border-top: 1px solid $primaryBorder;
      padding: 18px 8px 0px;

      .secondary-text {
        color: $secondaryText;
      }
      .date {
        display: flex;
        justify-content: center;
        padding-bottom: 20px;
      }

      .visitor-card-wrapper {
        .host-action-button-Container {
          padding: 8px 0px 20px;
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
    }
  }
}
.side-panel-container {
  .customer-selection-container {
    padding-bottom: 30px;
    border-bottom: 1px solid $primaryBorderBlurred;
  }
  .visits-selection-container {
    margin-top: 20px;
    .ms-ChoiceFieldLabel {
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 1px;
      margin-top: 1px;
    }
    .ms-ChoiceField-field.is-checked .ms-ChoiceFieldLabel {
      color: $primary;
    }
    .date-pickers-container {
      padding: 20px 0px;
      border-bottom: 1px solid $primaryBorderBlurred;
      .date-picker-row {
        display: flex;
        align-items: center;
        .date-picker {
          margin-left: 20px;
          width: 100%;
          max-width: 200px;
          .ms-TextField {
            width: 100%;
            .ms-TextField-fieldGroup {
              align-items: center;
              height: 40px;
              .ms-TextField-field {
                height: auto;
              }
              .ms-DatePicker-event--without-label {
                padding-top: 6px;
              }
            }
          }
        }
      }
    }
  }
  .visits-filter-container {
    margin-top: 20px;
    .filter-menu-item {
      padding-bottom: 14px;
      .ms-Checkbox .ms-Checkbox-text {
        font-size: 14px;
        font-weight: 400;
        letter-spacing: 1px;
      }
      .ms-Checkbox.is-checked .ms-Checkbox-text {
        color: $primary;
      }
    }
    .filter-callout-action {
      margin: 20px auto;
      width: 80%;
      min-width: 200px;
    }
  }
}
