@import "../../../assets//styles/variables.scss";

.customer-details {
  width: 100%;
  height: 100%;
  padding: 20px 16px;
  .customer-details-section {
    padding: 8px;
    border-bottom: 1px solid $primaryBorder;
    .customer-details-field-container {
      padding: 14px 0px;
      border-bottom: 1px solid $primaryBorderBlurred;
      .field-value {
        margin: 6px 0px 0px;
        display: block;
        color: $secondaryText;
        word-break: break-word;
      }
      .geopoint-details-container {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 6px;
        .lat-long-container {
          display: flex;
          align-items: center;
          .field-value {
            margin: 0px 0px 0px 5px;
            display: block;
            color: $secondaryText;
          }
        }
      }
      &:last-child {
        border: none;
      }
    }
  }

  .action-button-section {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
