.invite-security-guard {
  padding: 16px 16px 0px;
  background-color: #ffffff;
  height: 100%;
  width: 100%;
  .ms-FocusZone {
    display: flex;
    align-items: center;
    button {
      width: 50%;
      margin: 0;
    }
  }
}
