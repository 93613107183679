@import "../../assets//styles//variables.scss";

.visitor-card {
  background-color: #ffffff;
  padding: 16px 0px;
  border-bottom: 1px solid $primaryBorderBlurred;
  display: flex;

  .left-container {
    flex: 1;
    height: auto;
    .visitor-name-id-container {
      display: flex;
      align-items: center;
      .id-badge {
        margin-left: 20px;
        padding: 2px 4px;
        height: 16px;
        background-color: $primary;
        border-radius: 4px;
        display: flex;
        align-items: center;
      }
    }
    .fields-container {
      margin-top: 8px;
      .see-more-btn {
        color: $primary;
        cursor: pointer;
      }
      .see-more-btn:hover {
        border-bottom: 1px solid $primary;
      }
    }
  }
  .right-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    height: auto;
    .see-more-btn-container {
      .see-more-btn {
        color: $primary;
        cursor: pointer;
      }
      .see-more-btn:hover {
        border-bottom: 1px solid $primary;
      }
    }
  }
}
